<template>
  <div class="fuiler" id="fuiler">
    <div
      class="nav_block"
      :style="'background-image: url(' + current.ImageUrl + ')'"
    >
      <Header class="Header"></Header>
      <div class="Time">{{ current.TitleNumber }}</div>
      <div class="Title">#{{ current.Title }}#</div>
    </div>
    <div class="main">
      <div class="black">
        <div class="browse">
          <router-link
            :to="{
              path: '/mobile/browseArticles',
              query: { TitleNumber: current.TitleNumber, Title: current.Title },
            }"
            >浏览文章</router-link
          >
        </div>
        <div class="block" @click="black">返回</div>
      </div>
      <div class="content" v-html="current.text"></div>
      <div class="signUp" v-if="current.ColumnIng">
        <div class="New">
          <router-link to="/mobile/oper">点击进入操作指南</router-link>
        </div>
        <div class="Old">
          <router-link to="/mobile/sign">点击报名加入挑战</router-link>
        </div>
        <div class="arrow"></div>
      </div>
    </div>
    <Footer class="Footer"></Footer>
  </div>
</template>

<script>
import Header from "../conponents/Header";
import Footer from "../conponents/Footer";
import { find } from "../../../api/details";

export default {
  data() {
    return {
      current: {},
    };
  },
  mounted() {
    document.getElementById("fuiler").scrollIntoView();
    let query = this.$route.query;
    let TitleNumber = query.TitleNumber;
    let Title = query.Title;
    find({ TitleNumber, Title }).then((res) => {
      let current = res.sum[0];
      let text = current.text.replace(/\n/g, "<br>");
      current.text = text;
      this.current = current;
    });
  },
  components: {
    Header,
    Footer,
  },
  methods: {
    black() {
      this.$router.push("/mobile/home");
    },
  },
};
</script>

<style scoped lang="scss">
.fuiler {
  width: 100%;
  height: 100%;
  position: relative;
  background-size: 100% 100%;

  .Header {
    background-image: url("../../../assets/Image/public/header.png");
    background-size: 100% 100%;
    position: initial;
    height: 3rem;
  }

  .nav_block {
    position: relative;
    background-size: 130% 130%;
    background-position: center center;
    //background-image: url("../../../assets/Image/mobile/details/NavBg.png");
    width: 100%;
    height: 11rem;
    @mixin block {
      width: 100%;
      text-align: center;
      position: absolute;
      color: #ffffff;
      font-size: 1rem;
      font-style: italic;
    }

    .Time {
      @include block;
      top: 5rem;
    }

    .Title {
      @include block;
      font-size: 1.2rem;
      font-style: italic;
      top: 6.7rem;
    }
  }

  .main {
    width: 100%;
    min-height: 75rem;
    position: initial;

    .black {
      width: 100%;
      height: 2rem;
      margin-top: 0.8rem;
      margin-bottom: 0.3rem;
      position: relative;

      .browse {
        position: absolute;
        right: 7.5rem;
        border-radius: 0.4rem;
        font-size: 0.8rem;
        padding: 0.5rem 1.5rem;
        background-color: #93523a;
        a {
          color: #ffffff;
        }
      }

      .block {
        position: absolute;
        right: 1.5rem;
        border-radius: 0.4rem;
        font-size: 0.8rem;
        padding: 0.5rem 1.5rem;
        background-color: #93523a;
        color: #ffffff;
      }
    }

    .content {
      width: 20rem;
      padding: 1rem;
      margin: 0 auto;
      font-size: 0.8rem;
    }
  }

  .signUp {
    width: 100%;
    height: 2.7rem;
    display: flex;
    justify-content: space-around;
    position: relative;
    margin-top: 2rem;
    margin-bottom: 5rem;
    @mixin Block {
      text-align: center;
      width: 8rem;
      height: 2.7rem;
      line-height: 2.7rem;
      font-size: 0.3rem;
      border-radius: 0.6rem;
    }

    .New {
      @include Block;
      box-shadow: 0 0 7px 1px #78432e;

      a {
        color: #93523a;
        text-decoration: none;
      }
    }

    .Old {
      @include Block;
      color: #ffffff;
      background-color: #93523a;

      a {
        color: #ffffff;
        text-decoration: none;
      }
    }

    .arrow {
      background-image: url("../../../assets/Image/mobile/home/Arrow.png");
      background-size: 100% 100%;
      position: absolute;
      width: 2rem;
      height: 0.5rem;
      top: 50%;
      margin-top: -0.4rem;
    }
  }

  .Footer {
    background-image: url("../../../assets/Image/public/header.png");
    background-size: 100% 100%;
    position: initial;
  }
}
</style>
